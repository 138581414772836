@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import url('https://fonts.googleapis.com/css2?family=Euphoria+Script&display=swap');

@font-face {
  font-family: 'Josefin Sans';
  src: local('JosefinSans'), url(./fonts/JosefinSans-VariableFont_wght.ttf) format('truetype');
}


.lyrics-and-chords {
  white-space: pre;
  color: blue;
  font-family: 'Courier New', Courier, monospace;
}

.navbar-collapse {
  flex-grow: 0 !important
}

.navbar-brand, .signature {
  font-family: 'Euphoria Script', cursive;
  font-weight: bold;
  font-size: 2.5rem !important;
}

:root {
  --bs-headings-font-family: 'Euphoria Script', cursive;
  --bs-body-font-style: 'Euphoria Script', cursive;
}

h1, h3{
  font-family: 'Josefin Sans';
}

.zoom {
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.03);
}

.react-datepicker-wrapper {
  width: 100%;
}

.package-card {
  position: relative;
  /* min-width: 320px; */
  height: 280px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
.package-card-content {
  width: 100%;
  height: 100%;
  padding: 30px;
}

.package-card-container {
  width: 100%;
  min-width: 320px;
}

.staticSongList {
  min-width: 300px;
  text-align: center;
  padding: 10px 0px 10px 0px;
}


.image {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the image covers the container */
}

.hide {
  top: -86px !important; /* Hide the navbar 50 px outside of the top view */
  transition: top 0.3s; 
}
.show {
  top: 0;
  transition: top 0.3s; 
}

.diagonal-divs {
  
  /* -ms-transform: skewX(-10deg); 
  -webkit-transform: skewX(-10deg);
   transform: skewX(-10deg); 
  /* justify-content: space-between; */
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  height: 100vh;
  /* width: 100%;  */
  /* z-index: 1; */
  align-items: center;
  justify-content: center;
}

.left-diagonal-div {
  flex: 1;
  /* -ms-transform: skewX(-10deg);
  -webkit-transform: skewX(-10deg);
  transform: skewX(-10deg); */
  /* background-color: #f8f9fa; */
  height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* width: 50%; */
  color: white;

} 

.right-diagonal-div {
  flex: 1;
  /* -ms-transform: skewX(-10deg); */
  /* -webkit-transform: skewX(-10deg); */
  /* transform: skewX(-10deg); */
  /* background-color: #f8f9fa; */
  height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  
  /* position: absolute; */
  /* width: 50%; */
} 
.left-diagonal-div:hover, .right-diagonal-div:hover, .dark-overlay {
  background-color: rgba(0, 0, 0, 0.21)
}
